// src/pages/Jobs.jsx

import React, { useEffect, useState } from 'react';
import Navbar from '../components/Navbar';
import Header from '../components/Header';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import Modal from '../components/Modal';
import { Link } from 'react-router-dom';
import Footer from "../components/Footer";

export default function Jobs({ user }) {
  const [jobs, setJobs] = useState([]);
  const [open, setOpen] = useState(false);
  const [description, setDescription] = useState('');

  const getJobs = async () => {
    const item = await getDoc(doc(getFirestore(), 'users', `${user.uid}`));
    setJobs(item.data()?.roles || []);
    return item.data()?.roles;
  };

  const openDescription = (job) => {
    setOpen(true);
    console.log('job', user);
    setDescription(job.role.description);
  };

  useEffect(() => {
    getJobs();
  }, []);

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-grow">
        <Header title={'Candidates'} />
        <div className='bg-lightgray pb-8 mx-auto max-w-[1440px]'>
          <div className='flex bg-gray-850 font-semibold px-3 py-1'>
            <div className='w-full'>Candidates for Role</div>
            {/* <div className='w-1/3'>CV Screened</div> */}
          </div>
          <Modal open={open} setOpen={setOpen}>
            <p>{description}</p>
            <div className="bg-gray-50 py-3 flex z-50 items-center justify-center gap-4 sm:px-6">
              <button
                onClick={() => setOpen(false)}
                className="btn-secondary disabled"
              >
                OK
              </button>
              <button
                className="btn-secondary"
                onClick={() => setOpen(false)}>
                Cancel
              </button>
            </div>
          </Modal>
          {jobs.map((job, index) => {
            const roleUrl = `/role/${job.url}`;
            return (
              <div key={index} className='flex font-normal px-3 py-2'>
                <div className='w-full'>
                  <Link to={roleUrl} className='text-blue-500 underline'>
                    {job.role.jobTitle}
                  </Link>
                </div>
                {/* <div className='w-1/3'>CV Screened</div> */}
              </div>
            );
          })}
        </div>
      </div>
      <Footer />
    </div>
  );
}
