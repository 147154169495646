export const analyzeCV = async (payload) => {
    return await fetch('https://api.alindor.tech/analyze_CVs/', {
        method: 'POST',
        body: payload,
    }).then(res => {
        return res.json()
    });
}

export const getTask = async (id) => {
    const res = await fetch(`https://api.alindor.tech/tasks/${id}`)
    return await res.json()
}