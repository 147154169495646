// src/pages/UploadCVS.jsx:

import React from "react";
import UploadNewCVs from "./UploadNewCVs";
import Navbar from "../components/Navbar";
import Header from "../components/Header";

export default function UploadCVS({ user }) {
  return (
    <div>
      <Header title={"Add New Candidates"} />
      <UploadNewCVs user={user} />
    </div>
  );
}
