// src/pages/Candidates.jsx
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Navbar from '../components/Navbar';
import { useParams, Link } from 'react-router-dom';
import Footer from "../components/Footer";

export default function CandidatesCards({ user }) {
    const [recommendedResults, setRecommendedResults] = useState([]);
    const [notRecommendedResults, setNotRecommendedResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [jobTitle, setJobTitle] = useState('');
    const params = useParams();

    const getUsers = async (all) => {
        const currentRoleUrl = params.roleUrl;
        let notRecom = [];
        let recom = [];
        for (const { task_result, roleUrl } of all) {
            if (task_result) {
                Object.values(task_result).forEach((value) => {
                    if (typeof value === 'object' && roleUrl === currentRoleUrl) {
                        if (task_result.job_title) {
                            setJobTitle(task_result.job_title);
                        }
                        const locationColor = value['Candidates page view']?.location?.[1];
                        if (!value.Score || value.Score <= 3 || locationColor === 'red') {
                            notRecom.push({ ...value, roleUrl });
                        } else {
                            recom.push({ ...value, roleUrl });
                        }
                    }
                });
            }
        }
    
        setTimeout(() => {
            setRecommendedResults(recom.sort((p1, p2) => (p1.Score < p2.Score) ? 1 : (p1.Score > p2.Score) ? -1 : 0));
            setNotRecommendedResults(notRecom.sort((p1, p2) => (p1.Score < p2.Score) ? 1 : (p1.Score > p2.Score) ? -1 : 0));
            setLoading(false);
        }, 1);
    };
    
    
    useEffect(() => {
        setLoading(true);
        getDoc(doc(getFirestore(), 'users', `${user.uid}`)).then((item) => {
            getUsers(item.data()?.results || []);
        });
    }, [user.uid]);

    const getColorForRate = (rate) => {
        if (rate <= 3.3) return 'red';
        if (rate <= 6.6) return 'yellow';
        return 'green';
    };

    const truncateText = (text, charLimit) => {
        if (text.length > charLimit) {
            return text.slice(0, charLimit) + '...';
        }
        return text;
    };

    const truncateLink = (link) => {
        return link.length > 30 ? link.slice(0, 30) + '...' : link;
    };

    const getEmojiForColor = (color) => {
        switch (color) {
            case 'red':
                return '🔴';
            case 'yellow':
                return '🟡';
            case 'green':
                return '🟢';
            default:
                return '';
        }
    };

    const getLocationEmoji = (color) => {
        switch (color) {
            case 'red':
                return '❌';
            case 'yellow':
                return '❓';
            default:
                return '';
        }
    };

    const renderLink = (link) => {
        const truncatedLink = truncateLink(link);
        if (link.includes('@')) {
            return <a key={link} href={`mailto:${link}`} className='block text-white hover:underline text-sm overflow-hidden text-ellipsis whitespace-nowrap'>{truncatedLink}</a>;
        } else if (/^\d+$/.test(link) || link.includes(' ') || !link.includes('.')) {
            return <span key={link} className='block text-sm overflow-hidden text-ellipsis whitespace-nowrap'>{truncatedLink}</span>;
        } else {
            return <a key={link} href={link.startsWith('http') ? link : `http://${link}`} className='block text-white hover:underline text-sm overflow-hidden text-ellipsis whitespace-nowrap'>{truncatedLink}</a>;
        }
    };

    const renderCard = (candidate) => {
        const {
            'Full name': fullName,
            'Candidates page view': {
                full_name,
                overall_rate,
                links,
                experience,
                location,
                skills,
                higher_education,
                ...rest
            } = {}
        } = candidate;
    
        const renderAdditionalFields = () => {
            return Object.keys(rest).map((key) => {
                const value = rest[key];
                if (Array.isArray(value) && value.length === 2) {
                    const [info, color] = value;
                    return <span key={key} className="mr-4">{getEmojiForColor(color)} {info}</span>;
                } else {
                    return null;
                }
            });
        };
    
        return (
            <div className='bg-secondary w-full max-w-4xl mx-auto my-4 rounded p-4 text-white grid grid-cols-[80px,1fr,2fr] gap-0 relative'>
                <div className='flex items-start'>
                    <p className={`text-${getColorForRate(overall_rate)}-500 text-4xl font-bold`}>{overall_rate}</p>
                </div>
                <div className='flex flex-col justify-between'>
                    <Link to={`/standardized-cv/${fullName || full_name}`} className="text-2xl font-semibold hover:underline">
                        {fullName || full_name}
                    </Link>
                    {location && <p className="mb-2">{getLocationEmoji(location[1])} {location[0]}</p>}
                    {links && links.length > 0 && (
                        links.map((link) => renderLink(link))
                    )}
                </div>
                <div className='flex flex-col justify-between'>
                    {experience && <p>{getEmojiForColor(experience[1])} {experience[0]}</p>}
                    {skills && (
                        <p title={skills[0]}>
                            {getEmojiForColor(skills[1])} {truncateText(skills[0], 60)}
                        </p>
                    )}
                    {higher_education && (
                        <p title={higher_education[0]}>
                            {getEmojiForColor(higher_education[1])} {truncateText(higher_education[0], 60)}
                        </p>
                    )}
                    <div className="flex flex-wrap justify-">
                        {renderAdditionalFields()}
                    </div>
                </div>
                <Link
                    to={`/standardized-cv/${encodeURIComponent(fullName || full_name)}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className='absolute top-2 right-2 text-white hover:underline bg-lightgray px-2 py-1 rounded'
                >
                    CV
                </Link>
            </div>
        );
    };

    const renderSection = (title, data) => (
        <div className='w-full'>
            <h2 className='text-center text-2xl font-semibold my-4'>{title}</h2>
            {data.map((candidate, index) => (
                <div key={index} className='w-full'>
                    {renderCard(candidate)}
                </div>
            ))}
        </div>
    );

    return (
        <div className="flex flex-col min-h-screen">
            <div className="flex-grow">
                <Header title={jobTitle ? `Candidates for ${jobTitle}` : 'Candidates for new role'} />
                <div className="flex flex-col items-center">
                    {loading ? (
                        <div className="text-center py-8">
                            <div className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]" role="status">
                                <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">Loading...</span>
                            </div>
                        </div>
                    ) : (
                        <>
                            {renderSection('Recommended', recommendedResults)}
                            {renderSection('Not Recommended', notRecommendedResults)}
                        </>
                    )}
                </div>
            </div>
            <Footer />
        </div>
    );
    
}
