// src/pages/SignInPage.jsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithGooglePopup, signInWithEmailAndPassword } from '../firebase';
import google from './../assets/google.png';

const SignInPage = ({ setUser }) => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMsg, setErrorMsg] = useState('');

    const handleGoogleSignIn = async () => {
        try {
            const res = await signInWithGooglePopup();
            setUser(res.user);
            navigate('/add-cvs');
        } catch (error) {
            setErrorMsg(error.message);
        }
    };

    const handleEmailSignIn = async (e) => {
        e.preventDefault();
        try {
            const res = await signInWithEmailAndPassword(email, password);
            setUser(res.user);
            navigate('/add-cvs');
        } catch (error) {
            setErrorMsg(error.message);
        }
    };

    return (
        <div className="min-h-screen flex flex-col items-center justify-center py-6 px-4">
            <form onSubmit={handleEmailSignIn} className="space-y-6 max-w-md w-full">
                <h3 className="text-3xl font-extrabold mb-8">Sign In</h3>
                {errorMsg && <p className='text-red-500'>{errorMsg}</p>}
                <div>
                    <button type="button" onClick={handleGoogleSignIn}
                        className="border flex px-4 bg-white py-2 items-center rounded gap-4">
                        <p>Sign in with Google</p>
                        <img src={google} alt="google" className='w-8' />
                    </button>
                </div>
                <div className="mt-6">
                    <label className="block text-sm font-medium text-gray-700">Email address</label>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                        required
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Password</label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                        required
                    />
                </div>
                <button type="submit" className="w-full py-2 px-4 bg-blue-600 text-white rounded">
                    Sign In with Email
                </button>
            </form>
            <p className="mt-4">
                Don't have an account? <a href="/signup" className="text-blue-600">Sign Up</a>
            </p>
        </div>
    );
};

export default SignInPage;
