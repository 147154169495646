// src/components/Footer.jsx


import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className='py-4 px-8 text-center bg-black text-white'>
            <p>Alindor Corp. Delaware, US.</p>
            <Link to="/privacy" className="text-white underline">Privacy Policy</Link>
            <span> | </span>
            <Link to="/terms" className="text-white underline">Terms</Link>
        </footer>
    );
};

export default Footer;
