// src/pages/Billing.jsx
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Header from '../components/Header';
import axios from 'axios';
import qs from 'qs';
import Footer from "../components/Footer";
import { Tooltip } from 'react-tooltip';  // Import Tooltip

const Billing = ({ user }) => {
    const [billingType, setBillingType] = useState('Yearly');
    const [status, setStatus] = useState(null);
    const [tokenBalance, setTokenBalance] = useState(0);
    const location = useLocation();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const statusParam = searchParams.get('status');

        if (statusParam === 'success') {
            setStatus('success');
        } else if (statusParam === 'error') {
            setStatus('error');
        } else {
            setStatus(null);
        }
    }, [location.search]);

    useEffect(() => {
        const fetchTokenBalance = async () => {
            try {
                const response = await axios.get('https://api.alindor.tech/get-token-balance/', {
                    params: {
                        user_email: user.email
                    }
                });
                setTokenBalance(response.data.tokens);
            } catch (error) {
                console.error("Error fetching token balance:", error);
                if (error.response) {
                    console.error('Error response data:', error.response.data);
                    console.error('Error response status:', error.response.status);
                    console.error('Error response headers:', error.response.headers);
                }
            }
        };

        fetchTokenBalance();
    }, [user.email]);

    const plans = {
        Yearly: { Premium: '$900/month' },
        Monthly: { Premium: '$1300/month' }
    };

    const currentPlan = plans[billingType];

    const handleCheckout = async (planType) => {
        try {
            const quantity = planType === 'Premium' ? (billingType === 'Yearly' ? 900 : 1300) : 0;
            const packageName = planType;
            const commitmentType = billingType;

            const data = {
                user_email: user.email,
                package_name: packageName,
                commitment_type: commitmentType
            };

            const response = await axios.post('https://api.alindor.tech/create-subscription/', qs.stringify(data), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            });

            window.open(response.data.checkout_url, '_blank');
        } catch (error) {
            console.error("Error creating checkout session:", error);
            if (error.response) {
                console.error('Error response data:', error.response.data);
                console.error('Error response status:', error.response.status);
                console.error('Error response headers:', error.response.headers);
            }
        }
    };

    return (
        <div className="flex flex-col min-h-screen">
            <div className="flex-grow">
                <Header title={'Billing'} />
                {status === 'success' && (
                    <div className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4" role="alert">
                        <p className="font-bold">Payment Successful!</p>
                        <p>Thank you for your purchase. Your subscription is now active.</p>
                    </div>
                )}
                {status === 'error' && (
                    <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4" role="alert">
                        <p className="font-bold">Payment Failed</p>
                        <p>There was an issue with your payment. Please try again.</p>
                    </div>
                )}
                <div className="flex justify-center my-6">
                    <button
                        className={`px-4 py-2 mx-2 border ${billingType === 'Yearly' ? 'bg-black text-white' : 'bg-white text-black'}`}
                        onClick={() => setBillingType('Yearly')}
                    >
                        Yearly
                    </button>
                    <button
                        className={`px-4 py-2 mx-2 border ${billingType === 'Monthly' ? 'bg-black text-white' : 'bg-white text-black'}`}
                        onClick={() => setBillingType('Monthly')}
                    >
                        Monthly
                    </button>
                </div>
                <div className="flex justify-center mb-6">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-72 text-center">
                        <h2 className="text-2xl font-bold mb-2" data-tooltip-id="tooltip-early-adopter">
                            Early Adopter Plan
                        </h2>
                        <Tooltip id="tooltip-early-adopter" place="top" effect="solid">
    This price is a special reduced rate for early adopters.<br />
    Currently, the app is in beta version, so the price is lower.<br />
    In the future, it will be much higher for regular users.<br />
    Take advantage of becoming an early adopter!
</Tooltip>

                        <p className="text-xl mb-4">{currentPlan.Premium}*</p>
                        <button className="btn mb-4" onClick={() => handleCheckout('Premium')}>Buy</button>
                        <p>*Billed {billingType} until canceled</p>
                        <p>✓ Up to 1 million CVs/month</p>
                        <p>✓ Personal support manager</p>
                    </div>
                </div>

                <p className="text-yellow-600 mb-6 text-center">7-day money-back guarantee on all purchases.</p>
                <div className="text-center mb-6">
                    <br />
                    <br />
                    <h3 className="text-xl font-bold">Your Current Balance: {tokenBalance} CVs</h3>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Billing;
