// FileUPload.jsx
import React, { useEffect, useState } from 'react';
import upload from '../uploader';
import minus from './../assets/minus.png';
import Toast from './Toast';

export default function FileUpload({ open, Cvs, setCVs, setOpen, setUploaded, setAnalyzeDis, description, userEmail }) {
    const [errorMsg, setErrorMsg] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [progress, setProgress] = useState(0);

    const confirm = () => {
        setOpen(false);
        setUploaded(true);
        if (description) {
            setAnalyzeDis(false);
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const droppedFiles = e.dataTransfer.files;
        if (droppedFiles.length > 0) {
            setDisabled(true); // Disable the upload button while uploading
            upload(droppedFiles, setDisabled, setCVs, setErrorMsg, setProgress)
                .then(() => setDisabled(false)) // Re-enable the upload button
                .catch((error) => setErrorMsg(error.message));
        }
    };

    const handleChange = (e) => {
        const files = e.target.files;
        if (files.length !== 0) {
            setDisabled(true); // Disable the upload button while uploading
            upload(files, setDisabled, setCVs, setErrorMsg, setProgress)
                .then(() => setDisabled(false)) // Re-enable the upload button
                .catch((error) => setErrorMsg(error.message));
        }
    };

    const deleteFile = (file) => {
        const newArr = Cvs.filter(cv => cv !== file);
        setCVs(newArr);
        if (newArr.length === 0) {
            setDisabled(true);
            setAnalyzeDis(true);
            setUploaded(false);
        }
    };

    useEffect(() => {
        setErrorMsg('');
    }, [open]);

    return (
        <>
            <div className='pt-10 pl-4 absolute top-0 left-0 w-full'>
                {errorMsg && <p className='text-red-500'>{errorMsg}</p>}
                {Cvs?.map((file, index) => {
                    return <div key={index} className='flex items-center gap-5'>
                        <p>{file}</p>
                        <Toast title={'delete'} mt={'bottom-5'}>
                            <button className='w-4 rounded-full' onClick={() => deleteFile(file)}>
                                <img src={minus} className='w-full' alt="minus" />
                            </button>
                        </Toast>
                    </div>
                })}
            </div>
            <div className='flex h-full items-center'>
                <div onDrop={handleDrop}
                    onDragOver={(event) => event.preventDefault()}
                    className='h-60 w-full flex items-center'>
                    <button className='btn-outline relative w-auto mx-auto'>
                        <input multiple
                            accept=".pdf" // Allow only PDF files
                            onChange={(e) => handleChange(e)}
                            type="file"
                            className='absolute cursor-pointer w-full h-full top-0 opacity-0 left-0' />
                        Open files
                    </button>
                </div>
            </div>
            {progress > 0 && progress < 100 && (
                <div className='w-full bg-gray-200 h-2.5 mt-4'>
                    <div className='bg-blue-600 h-2.5' style={{ width: `${progress}%` }}></div>
                </div>
            )}
            <div className="bg-gray-50 px-4 py-3 flex items-center justify-center gap-4 sm:px-6">
                <button
                    className="btn-secondary"
                    onClick={() => confirm()}
                    disabled={disabled}>
                    Upload
                </button>
                <button
                    className="btn-secondary"
                    onClick={() => setOpen(false)} >
                    Cancel
                </button>
            </div>
        </>
    );
}
