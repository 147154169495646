// src/pages/ResetPasswordPage.jsx
import React, { useState } from 'react';
import { sendPasswordReset } from '../firebase';

const ResetPasswordPage = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [errorMsg, setErrorMsg] = useState('');

    const handlePasswordReset = async (e) => {
        e.preventDefault();
        try {
            await sendPasswordReset(email);
            setMessage('Password reset email sent! Please check your email.');
        } catch (error) {
            setErrorMsg(error.message);
        }
    };

    return (
        <div className="min-h-screen flex flex-col items-center justify-center py-6 px-4">
            <form onSubmit={handlePasswordReset} className="space-y-6 max-w-md w-full">
                <h3 className="text-3xl font-extrabold mb-8">Reset Password</h3>
                {message && <p className='text-green-500'>{message}</p>}
                {errorMsg && <p className='text-red-500'>{errorMsg}</p>}
                <div>
                    <label className="block text-sm font-medium text-gray-700">Email address</label>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                        required
                    />
                </div>
                <button type="submit" className="w-full py-2 px-4 bg-blue-600 text-white rounded">
                    Send Password Reset Email
                </button>
            </form>
        </div>
    );
};

export default ResetPasswordPage;
