// src/firebase.js
import { getAuth, signInWithPopup, GoogleAuthProvider, signInWithEmailAndPassword as firebaseSignInWithEmail, createUserWithEmailAndPassword as firebaseCreateUserWithEmail, sendEmailVerification, sendPasswordResetEmail } from "firebase/auth";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import { getFirestore } from "firebase/firestore";
import "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyCasKX3pFS5OkdnELDRra6kCsHpCA0Mk7g",
    authDomain: "toshpulatov-dev.firebaseapp.com",
    projectId: "toshpulatov-dev",
    databaseURL: "https://toshpulatov-dev-default-rtdb.firebaseio.com",
    storageBucket: "toshpulatov-dev.appspot.com",
    messagingSenderId: "974184919285",
    appId: "1:974184919285:web:d420d1c303cc8d4baa55bc"
};

export const FireBase = firebase.initializeApp(firebaseConfig);

const provider = new GoogleAuthProvider();

provider.setCustomParameters({
    prompt: "select_account "
});

export const database = getFirestore(FireBase);
export const storage = firebase.storage();
export const auth = getAuth();
export const signInWithGooglePopup = () => signInWithPopup(auth, provider);
export const signInWithEmailAndPassword = (email, password) => firebaseSignInWithEmail(auth, email, password);
export const createUserWithEmailAndPassword = (email, password) => firebaseCreateUserWithEmail(auth, email, password);
export const sendVerificationEmail = (user) => sendEmailVerification(user);
export const sendPasswordReset = (email) => sendPasswordResetEmail(auth, email);
