// src/pages/Main.jsx

import { doc, getDoc, getFirestore, setDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import arrow from './../assets/arrow.svg';
import unstructuredCV from './../assets/unstructuredCV.gif';
import standardizedCV from './../assets/standardizedCV.gif';
import scoreExplanation from './../assets/ScoreAlexisOthers.png';
import Footer from '../components/Footer';
import { useRef } from 'react';

export default function Main({ user, setUser, loading }) {
    const [errorMsg, setErrorMsg] = useState('');

    const navigate = useNavigate();
    const iframeRef = useRef(null);
    const handleFullScreen = () => {
        if (iframeRef.current) {
            iframeRef.current.requestFullscreen();
        }
    };
    const handleAnalyzeClick = () => {
        if (!user) {
            navigate('/signin');  // Navigate to the Sign-In page instead of showing the Google Sign-In popup
        } else if (!user.emailVerified) {
            setErrorMsg('Please verify your email before accessing this feature.');
        } else {
            navigate('/add-cvs');
        }
    };

    useEffect(() => {
        const payload = JSON.parse(localStorage.getItem('analyzePayload'));
        if (user && payload) {
            localStorage.removeItem('analyzePayload');
        }
    }, [user]);

    return (
        <div className='min-h-screen flex flex-col justify-between'>
            <div className='sticky top-0 right-0 p-4' style={{ position: 'fixed', top: '20px', right: '20px', zIndex: 50 }}>
                <button
                    className='btn btn-primary text-2xl py-2 px-4'
                    onClick={handleAnalyzeClick}>
                    Launch App
                </button>
            </div>
            <div className='flex-grow flex flex-col justify-center items-center text-center px-8 md:px-40' style={{ marginTop: '180px' }}>
                <h1 className='font-bold md:text-8xl text-2xl sm:text-3xl mb-7'>Analyze Candidate CVs <br></br> with AI</h1>
                <h2 className='text-lg md:text-xl text-base sm:text-lg mb-10 max-w-3xl'>
                    With our AI tool, recruiters can score 1000+ candidates daily in 5 minutes. Recruiters don't need to open and check CVs manually because AI will parse CVs into a standardized, readable view and assign a score (1-10) for each candidate. Candidates will be sorted by score and ready for fast scheduling of the first interview.
                </h2>
                <button
                    className='btn btn-primary mt-4 text-2xl py-4 px-8 mb-40'
                    onClick={handleAnalyzeClick}>
                    Try 10 CVs for free
                </button>
                {errorMsg && <p className='text-red-500 max-w-64 capitalize mt-4'>{errorMsg}</p>}
            </div>
            <div className='text-center mb-20 mt-60'>
    <h1 className='font-bold text-5xl mb-6'>Don't Open CVs!</h1>
    <p className='text-lg md:text-xl text-base sm:text-lg mb-10 max-w-3xl mx-auto'>
        AI will analyze and filter all candidates by score. Double-check a score's justification and schedule interviews with top candidates.
    </p>
    <img src={scoreExplanation} alt="Score explanation" className='mx-auto' style={{ width: '600px', height: 'auto' }} />
</div>
<div className='text-center mb-20 mt-60'> 
    <h1 className='font-bold text-5xl mb-6'>Tired of Reading CVs<br />with Different Structures?</h1>
    <p className='text-lg md:text-xl text-base sm:text-lg mb-10 max-w-3xl mx-auto'>
        Recruiters receive thousands of CVs with annoying structures, unreadable blocks, and redundant information. Our AI tool eliminates the need to open and read "raw" CVs. Read only standardized CVs, which are the same for all candidates!
    </p>
    <div className='flex justify-center items-center'>
        <div className='mr-10'>
            <h2 className='font-semibold text-xl mb-4'>From Raw CV (.pdf)</h2>
            <img src={unstructuredCV} alt="Unstructured CV" width="440" height="480" className='loop' />
        </div>
        <img src={arrow} alt="Arrow" className='mx-2' style={{ width: '150px', height: '150px', transform: 'scaleX(-1)' }} />
        <div className='ml-10'>
            <h2 className='font-semibold text-xl mb-4'>To Standardized View</h2>
            <img src={standardizedCV} alt="Standardized CV" width="465" height="280" className='loop' />
        </div>
    </div>
</div>
<div className='text-center mb-40 mt-60'>
    <h1 className='font-bold text-5xl mb-6'>Check out a demo video!</h1>
    <p className='text-lg md:text-xl text-base sm:text-lg mb-10 max-w-3xl mx-auto'>
        Discover how our AI can revolutionize your recruitment process. Watch our demo to see it in action!
    </p>
    <div className='flex justify-center flex-col items-center'>
        <iframe
            ref={iframeRef}
            width="660"
            height="415"
            src="https://www.youtube.com/embed/gaHb2mONeFM"
            title="Demo Video"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
            allowFullScreen
        ></iframe>

    </div>
</div>


<div className='text-center mb-20'> {/* Additional "Try 10 CVs for free" button */}
    <button
        className='btn btn-primary mt-4 text-2xl py-4 px-8 mb-20'
        onClick={handleAnalyzeClick}>
        Try 10 CVs for free
    </button>
</div>
<Footer />

        </div>
    );
}
