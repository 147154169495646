// src/components/Navbar.jsx

import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import google from './../assets/google.png';
import menu from './../assets/menu.svg';

const Navbar = ({ user, setUser }) => {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    const links = [
        { name: "Home", path: "/" },
        { name: 'Candidates', path: `/jobs` },
        { name: 'Upload New CVs', path: `/add-cvs` },
        { name: "Billing", path: "/billing" },
    ];

    const handleLogout = () => {
        auth.signOut();
        setUser(null);
        navigate('/');
    };

    return (
        <nav className='flex mx-auto items-center px-4 justify-between max-w-5xl'>
            <Link className='font-semibold' to={'/'}>
                {/* Logo removed */}
            </Link>
            <ul className='flex md:justify-center flex-[2] justify-end gap-10 py-3.5 items-center w-full'>
                {links.map((item, index) => (
                    <Link key={index} className="hidden md:block text-xl font-medium py-2" to={item.path}>{item.name}</Link>
                ))}
            </ul>
            <div className='flex gap-4 py-3.5'>
                <div className=' justify-end flex'>
                    {user ? (
                        <button className='border bg-secondary text-white hover:bg-lightgray md:px-4 px-2 text-sm md:text-base py-1 rounded'
                            onClick={handleLogout}>Log out</button>
                    ) : (
                        <button onClick={() => navigate('/signin')}>
                            <img className='w-8' src={google} alt="icon" />
                        </button>
                    )}
                </div>
                <button onClick={() => setOpen(!open)} className='md:hidden'>
                    <img className='w-4' src={menu} alt="menu" />
                </button>
            </div>
            {open && (
                <div className='fixed top-14 left-0 w-full h-full bg-primary z-50'>
                    {links.map((item, index) => (
                        <Link className='block text-xl font-semibold px-4 py-2' key={index} to={item.path}>{item.name}</Link>
                    ))}
                </div>
            )}
        </nav>
    );
};

export default Navbar;
