// src/pages/UploadNewCVs.jsx
import React, { useEffect, useRef, useState } from "react";
import Modal from "../components/Modal";
import { useNavigate } from "react-router-dom";
import FileUpload from "../components/FileUpload";
import { analyzeCV, getTask } from "../service/api";
import { auth, database, signInWithGooglePopup } from "../firebase";
import Toast from "../components/Toast";
import { doc, getDoc, getFirestore, setDoc } from "firebase/firestore";
import Footer from "../components/Footer";

export default function UploadNewCVs({ user, setUser }) {
  const [open, setOpen] = useState(false);
  const [descOpen, setDescOpen] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const [description, setDescription] = useState("");
  const [CVs, setCVs] = useState([]);
  const [selectDisabled, setSelectDisabled] = useState(false);
  const [analyzeDis, setAnalyzeDis] = useState(true);
  const [loading, setLoading] = useState(false);
  const [hover, setHover] = useState(false);
  const [existingRoles, setExistingRoles] = useState([]);
  const [newRole, setNewRole] = useState(false);
  const [descHover, setDescHover] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [JDs, setJDs] = useState("");
  const [addRole, setAddRole] = useState(false);
  const [selected, setSelected] = useState(false);
  const select = useRef();
  const navigate = useNavigate();
  const [analyzing, setAnalyzing] = useState(false);
  const [selectedRole, setSelectedRole] = useState();
  const [errorMsg, setErrorMsg] = useState("");

  const getJobTitle = async () => {
    const item = await getDoc(doc(getFirestore(), "users", `${user.uid}`));
    setExistingRoles(item.data()?.roles || []);
    return item.data()?.roles || [];
  };

  const getAllResults = async () => {
    const item = await getDoc(doc(getFirestore(), "users", `${user.uid}`));
    return {
      allResults: item.data()?.results || [],
      allRoles: item.data()?.roles || [],
    };
  };

  const handleSelect = async (e) => {
    if (e.target.value) {
      if (e.target.value === "Add New Role") {
        setDescOpen(true);
        setNewRole(true);
      } else {
        setSelected(true);
        if (uploaded) {
          setAnalyzeDis(false);
        }
        setSelectDisabled(true);
        const job = existingRoles.find(
          (option) => option.role.jobTitle === e.target.value
        );
        setJDs(job.role.description);
        setSelectedRole(job.role.jobTitle);
      }
    }
  };

  const analyze = async () => {
    setErrorMsg("");
    const userData = new FormData();
    userData.append("firebase_admin_name", "toshpulatov-dev");
    userData.append("user_email", user.email);
    userData.append("cv_pdf_bucket_paths_list", CVs.join(","));
    userData.append("job_description", description);

    if (description !== "") {
      userData.append("existing_job_title", "");
    } else {
      userData.append("existing_job_title", selectedRole);
    }

    const { task_id } = await analyzeCV(userData);
    await getResult(task_id);
    setAnalyzing(true);
  };

  const getResult = async (task_id) => {
    console.log(task_id);
    const result = await getTask(task_id);

    if (result.task_status === "PENDING") {
      setTimeout(() => {
        getResult(task_id);
      }, 1000);
    }
    if (result.task_status === "SUCCESS") {
      const customUrl = result.task_result?.job_title
        .replaceAll(" ", "-")
        .toLowerCase();

      const { allResults, allRoles } = await getAllResults();
      const newResults = [...allResults];
      const newRoles = [...allRoles];

      const allCVs = Object.values(result.task_result).filter(
        (ob) => typeof ob === "object"
      );
      let i = 0;
      for (const cv of allCVs) {
        i++;
        const email = cv?.email_candidate;

        const emailExist = allResults.find(
          (rs) =>
            rs.email_candidate === email &&
            rs.task_result.job_title === result.task_result.job_title
        );

        if (!emailExist) {
          if (email) {
            allResults.push({
              ...{
                task_id: result.task_id,
                task_result: {
                  [cv + i]: cv,
                  job_title: result.task_result.job_title,
                  token_balance: result.task_result.token_balance,
                },
              },
              roleUrl: customUrl,
              email_candidate: email,
              task_status: result.task_status,
            });
          } else {
            const hasProblematicCV = Object.keys(result.task_result).includes(
              "problematic_cvs"
            );
            if (hasProblematicCV) {
              let fullName = "";
              const fullNameKey = Object.keys(
                result.task_result["problematic_cvs"]
              )[0];
              if (fullNameKey) {
                fullName = fullName =
                  fullNameKey.split("_").length === 2
                    ? fullNameKey.split("_")[1]
                    : fullNameKey.split("_")[0];
              }

              allResults.push({
                ...result,
                roleUrl: customUrl,
                task_result: {
                  problematic_cvs: {
                    "Full name": fullName,
                    Features: "Error",
                  },
                  job_title: result.task_result.job_title,
                },
              });
            }
          }
        }
      }

      console.log(
        "set results ",
        newResults,
        newRoles,
        result.task_result.job_title
      );
      const currentRole = newRoles.find(
        (role) => role.role?.jobTitle === result.task_result.job_title
      );
      console.log(currentRole, newRoles);

      if (!currentRole) {
        const role = {
          jobTitle: result.task_result.job_title,
          description: "",
        };
        newRoles.push({ role: role, url: customUrl });
      }

      setDoc(doc(database, `users/${user.uid}`), {
        results: allResults,
        roles: newRoles,
        task_id: task_id,
      });

      navigate(`/role/${customUrl}`);
    }
    if (result.task_status === "FAILURE") {
      setErrorMsg(result.task_result["Error"]);
      setAnalyzing(false);
    }
  };

  const handleChange = (e) => {
    const inpVal = e.target.value;
    if (inpVal.trim() !== "" && inpVal.length >= 300) {
      setDisabled(false);
      setDescription(inpVal);
    }
  };

  const confirm = async () => {
    setDescOpen(false);
    setSelectDisabled(true);
    if (newRole) {
      setAddRole(true);
    }
  };

  useEffect(() => {
    if (newRole && select.current) {
      select.current.value = "";
    }
  }, [open]);

  useEffect(() => {
    if (selected || description) {
      setAnalyzeDis(false);
      setErrorMsg("");
    }
  }, [uploaded]);

  useEffect(() => {
    if (CVs.length !== 0) {
      setAnalyzeDis(false);
      setErrorMsg("");
    }
  }, [selected]);

  useEffect(() => {
    setLoading(false);
    async function getRoles() {
      await getJobTitle();
      setLoading(false);
    }

    getRoles();
  }, []);

  const login = async () => {
    try {
      const res = await signInWithGooglePopup();
      setUser(res.user);
    } catch (error) {
      if (error.code === "auth/popup-closed-by-user") {
        setErrorMsg("Popup closed by user. Please sign in to continue.");
      } else {
        setErrorMsg(error.message);
      }
    }
  };

  const handleAnalyzeClick = () => {
    if (!user) {
      login();
    } else {
      analyze();
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <div className="bg-light py-5 flex-grow">
        <div className="max-w-5xl mx-auto px-4 newCVs">
          {loading && (
            <div className="fixed z-10 w-full text-white text-5xl font-mono flex items-center justify-center h-full top-0 left-0 bg-black/95 backdrop:blur-[50px]">
              <div className="flex mt-6 md:mt-12 md:items-center items-start justify-center w-full h-full">
                <div
                  className="inline-block h-14 w-14 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                  role="status"
                >
                  <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                    Loading...
                  </span>
                </div>
              </div>
            </div>
          )}
          <Modal open={descOpen} setOpen={setDescOpen}>
            <div className="flex h-full items-center justify-center py-6">
              <textarea
                defaultValue={description}
                onKeyDown={(e) => handleChange(e)}
                onChange={(e) => handleChange(e)}
                className="h-full placeholder:text-center w-full outline-none p-1.5"
                placeholder="Insert your job description here. You do not need to worry about the layout or structure. Just include the essence of the job, tasks, deliverables, requirements, qualifications, skills, etc"
              ></textarea>
            </div>
            <div className="bg-gray-50 px-4 py-3 flex items-center justify-center gap-4 sm:px-6">
              <button
                className="btn-secondary disabled"
                disabled={disabled}
                onClick={() => confirm()}
              >
                Save
              </button>
              <button
                className="btn-secondary"
                onClick={() => {
                  setDescOpen(false);
                  setNewRole(false);
                  if (select.current) select.current.value = "";
                }}
              >
                Cancel
              </button>
            </div>
          </Modal>

          <h2 className="text-center text-2xl md:text-3xl font-bold">
            {!selectDisabled
              ? "Select Role and Upload CVs"
              : "Select Role and Upload CVs"}
          </h2>
          <div className="flex flex-col sm:flex-row items-center justify-between gap-4 sm:gap-12 md::gap-20 mt-10 select-role-container bg-transparent">
            <p className="md:text-xl sm:text-base text-sm">
              {selectDisabled
                ? "Use the 'Select Role' dropdown to choose the role you wish to analyze. This helps tailor the candidate analysis to specific job requirements."
                : "Use the 'Select Role' dropdown to choose the role you wish to analyze. This helps tailor the candidate analysis to specific job requirements."}{" "}
            </p>
            <div className="w-44">
              {!addRole ? (
                <Toast title={"Select Role"}>
                  <select
                    ref={select}
                    name="select"
                    style={{ textWrap: "pretty" }}
                    onChange={(e) => handleSelect(e)}
                    className={
                      "text-sm btn w-44 text-white text-center rounded outline-none " +
                      (select.current?.value?.split("").length > 20 ? "h-16" : "")
                    }
                  >
                    <option value="" defaultValue hidden>
                      Select Role
                    </option>
                    {existingRoles.map((option, index) => {
                      return (
                        <option key={index} value={option.role.jobTitle}>
                          {option.role.jobTitle}
                        </option>
                      );
                    })}
                    <option value="Add New Role">Add New Role</option>
                  </select>
                </Toast>
              ) : (
                <Toast title={"Paste Job Description"}>
                  <button
                    onMouseEnter={() => setDescHover(true)}
                    onMouseLeave={() => setDescHover(false)}
                    onClick={() => setDescOpen(true)}
                    className="btn btn-secondary-active"
                  >
                    {descHover
                      ? "Paste Job Description"
                      : " Job Description Pasted!"}
                  </button>
                </Toast>
              )}
            </div>
          </div>
          <div className="flex flex-col sm:flex-row items-center justify-between gap-4 sm:gap-12 md::gap-20 mt-10 bg-transparent">
            <p className="md:text-xl sm:text-base text-sm">
              {selectDisabled
                ? "Upload CVs for candidates applying for the new role. You can upload multiple files at once."
                : "Upload CVs for candidates applying for the new role.  You can upload multiple files at once."}
            </p>
            <div className="w-44">
              <Toast title={"Upload CVs"}>
                <button
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
                  onClick={() => setOpen(!open)}
                  className={"btn " + (uploaded ? "btn-secondary-active" : "")}
                >
                  {uploaded
                    ? hover
                      ? "Upload CVs"
                      : `${CVs.length} CVs Uploaded!`
                    : "Upload CVs"}
                </button>
              </Toast>
            </div>
            <Modal open={open} setOpen={setOpen}>
              <FileUpload
                Cvs={CVs}
                description={JDs}
                open={open}
                setCVs={setCVs}
                setOpen={setOpen}
                setAnalyzeDis={setAnalyzeDis}
                selectDisabled={selectDisabled}
                setUploaded={setUploaded}
              />
            </Modal>
          </div>
          <div className="flex flex-col sm:flex-row items-center justify-between gap-4 sm:gap-12 md::gap-20 mt-10 bg-transparent">
            <p className="md:text-xl sm:text-base text-sm">
              {selectDisabled
                ? "Click 'Analyze New CVs' to start evaluating the uploaded CVs against the job description. Results will be shown within 60 sec."
                : "Click 'Analyze New CVs' to start evaluating the uploaded CVs against the job description. Results will be shown within 60 sec."}
            </p>
            <div className="w-64 flex md:justify-end justify-center flex-col items-end">
              <button
                className="btn w-44"
                onClick={() => handleAnalyzeClick()}
                disabled={analyzeDis || analyzing}
              >
                {analyzing ? "Analyzing..." : `Analyze New CVs`}
              </button>
              {errorMsg && (
                <p className="text-red-500 max-w-64 capitalize">{errorMsg}</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
