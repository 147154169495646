// StandardizedCV.jsx
import React, { useEffect, useState } from "react";
import { collection, getDocs, getFirestore, query, where } from "firebase/firestore";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "../components/Footer";

export default function StandardizedCV({ user }) {
  const [resume, setResume] = useState([]);
  const [experience, setExperience] = useState([]);
  const [projects, setProjects] = useState([]);
  const [achievementsAwards, setAchievementsAwards] = useState([]);
  const [academicPublications, setAcademicPublications] = useState([]);
  const [other, setOther] = useState([]);
  const [justificationData, setJustificationData] = useState([]);
  const { candidateName } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate("/signin");
      return;
    }
    getStandardizedCV();
  }, [user, candidateName]);

  const processCVData = (data) => {
    const resume = [];
    const experience = [];
    const projects = [];
    const achievementsAwards = [];
    const academicPublications = [];
    const other = [];

    data.forEach((item) => {
      const { FEATURE, VALUE } = item;
      if (VALUE === null) return;

      switch (FEATURE) {
        case "EXPERIENCE":
          if (Array.isArray(VALUE)) {
            experience.push(...VALUE);
          }
          break;
        case "PROJECTS":
          if (Array.isArray(VALUE)) {
            projects.push(...VALUE);
          }
          break;
        case "ACHIEVEMENTS AWARDS":
          if (Array.isArray(VALUE)) {
            achievementsAwards.push(...VALUE);
          }
          break;
        case "ACADEMIC PUBLICATIONS":
          if (Array.isArray(VALUE)) {
            academicPublications.push(...VALUE);
          }
          break;
        case "OTHER":
          if (Array.isArray(VALUE)) {
            other.push(...VALUE);
          }
          break;
        default:
          resume.push(item);
          break;
      }
    });

    setResume(resume);
    setExperience(experience);
    setProjects(projects);
    setAchievementsAwards(achievementsAwards);
    setAcademicPublications(academicPublications);
    setOther(other);
  };

  const processJustificationData = (data) => {
    setJustificationData(data);
  };

  const getStandardizedCV = async () => {
    try {
      const candidateQuery = query(
        collection(getFirestore(), "candidates"),
        where("candidate_name", "==", candidateName)
      );
      const candidateQuerySnapshot = await getDocs(candidateQuery);

      if (!candidateQuerySnapshot.empty) {
        const candidateDoc = candidateQuerySnapshot.docs[0];
        const { analyzed_data } = candidateDoc.data();

        const CV = analyzed_data ? analyzed_data["Table view"] : [];
        const JustificationView = analyzed_data
          ? analyzed_data["Justification view"]
          : [];

        processCVData(CV);
        processJustificationData(JustificationView);
      } else {
        console.log("No matching documents found.");
      }
    } catch (error) {
      console.error("Error fetching standardized CV:", error);
    }
  };

  const renderTable = (title, data) => (
    <div className="bg-gray-350 max-w-5xl mx-auto mt-8 rounded p-2">
      <h2 className="text-center text-2xl font-semibold">{title}</h2>
      <table
        className="table-auto w-full"
        style={{ borderCollapse: "collapse" }}
      >
        <tbody>
          {data.map((item, index) => (
            <tr key={index} className="bg-white">
              <td
                className="border px-4 py-2 w-1/4"
                style={{ border: "2px solid black" }}
              >
                {item.FEATURE}
              </td>
              <td
                className="border px-4 py-2 w-3/4 whitespace-pre-line"
                style={{ border: "2px solid black" }}
              >
                {item.VALUE}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const renderExperienceTable = (title, data) => (
    <div className="bg-gray-350 max-w-5xl mx-auto mt-8 rounded p-2">
      <h2 className="text-center text-2xl font-semibold">{title}</h2>
      <table
        className="table-auto w-full"
        style={{ borderCollapse: "collapse" }}
      >
        <tbody>
          {data.map((item, index) => (
            <React.Fragment key={index}>
              <tr className="bg-gray-100">
                <td
                  className="border px-4 py-2 w-1/4 text-xl font-bold"
                  style={{ border: "2px solid black" }}
                >
                  COMPANY
                </td>
                <td
                  className="border px-4 py-2 w-3/4 text-xl font-bold"
                  style={{ border: "2px solid black" }}
                >
                  {item.COMPANY}
                </td>
              </tr>
              <tr className="bg-white">
                <td
                  className="border px-4 py-2 w-1/4"
                  style={{ border: "2px solid black" }}
                >
                  JOB TITLE
                </td>
                <td
                  className="border px-4 py-2 w-3/4 whitespace-pre-line"
                  style={{ border: "2px solid black" }}
                >
                  {item["JOB TITLE"]}
                </td>
              </tr>
              <tr className="bg-white">
                <td
                  className="border px-4 py-2 w-1/4"
                  style={{ border: "2px solid black" }}
                >
                  START DATE
                </td>
                <td
                  className="border px-4 py-2 w-3/4 whitespace-pre-line"
                  style={{ border: "2px solid black" }}
                >
                  {item["START DATE"]}
                </td>
              </tr>
              <tr className="bg-white">
                <td
                  className="border px-4 py-2 w-1/4"
                  style={{ border: "2px solid black" }}
                >
                  END DATE
                </td>
                <td
                  className="border px-4 py-2 w-3/4 whitespace-pre-line"
                  style={{ border: "2px solid black" }}
                >
                  {item["END DATE"]}
                </td>
              </tr>
              <tr className="bg-white">
                <td
                  className="border px-4 py-2 w-1/4"
                  style={{ border: "2px solid black" }}
                >
                  DURATION
                </td>
                <td
                  className="border px-4 py-2 w-3/4 whitespace-pre-line"
                  style={{ border: "2px solid black" }}
                >
                  {item["DURATION"]}
                </td>
              </tr>
              <tr className="bg-white">
                <td
                  className="border px-4 py-2 w-1/4"
                  style={{ border: "2px solid black" }}
                >
                  DESCRIPTION
                </td>
                <td
                  className="border px-4 py-2 w-3/4 whitespace-pre-line"
                  style={{ border: "2px solid black" }}
                >
                  {item["DESCRIPTION"]}
                </td>
              </tr>
              {index < data.length - 1 && (
                <tr className="bg-gray-200">
                  <td
                    colSpan={2}
                    className="border"
                    style={{ border: "2px solid black", height: "10px" }}
                  ></td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );

  const renderSubTable = (title, data) => (
    <div className="bg-gray-350 max-w-5xl mx-auto mt-8 rounded p-2">
      <h2 className="text-center text-2xl font-semibold">{title}</h2>
      <table
        className="table-auto w-full"
        style={{ borderCollapse: "collapse" }}
      >
        <tbody>
          {data.map((item, index) =>
            Object.keys(item).map((subKey, subIndex) => (
              <tr key={`${index}-${subIndex}`} className="bg-white">
                <td
                  className="border px-4 py-2 w-1/4"
                  style={{ border: "2px solid black" }}
                >
                  {subKey}
                </td>
                <td
                  className="border px-4 py-2 w-3/4 whitespace-pre-line"
                  style={{ border: "2px solid black" }}
                >
                  {item[subKey]}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );

  const renderJustificationTable = (title, data) => (
    <div className="bg-gray-350 max-w-5xl mx-auto mt-8 rounded p-2">
      <h2 className="text-center text-2xl font-semibold">{title}</h2>
      <table
        className="table-auto w-full"
        style={{ borderCollapse: "collapse" }}
      >
        <tbody>
          {data.map((item, index) => (
            <React.Fragment key={index}>
              <tr className="bg-gray-100">
                <td
                  className="border px-4 py-2 w-1/4"
                  style={{ border: "2px solid black" }}
                >
                  {item.FEATURE}
                </td>
                <td
                  className="border px-4 py-2 w-3/4 whitespace-pre-line"
                  style={{ border: "2px solid black" }}
                >
                  {Array.isArray(item.VALUE)
                    ? item.VALUE.map((subItem, subIndex) => (
                        <div key={subIndex}>
                          {Object.keys(subItem).map((subKey, subSubIndex) => (
                            <div key={subSubIndex}>
                              <strong>{subKey}:</strong> {subItem[subKey]}
                            </div>
                          ))}
                          {subIndex < item.VALUE.length - 1 && <hr />}
                        </div>
                      ))
                    : item.VALUE}
                </td>
              </tr>
              {index < data.length - 1 && (
                <tr className="bg-gray-200">
                  <td
                    colSpan={2}
                    className="border"
                    style={{ border: "2px solid black", height: "10px" }}
                  ></td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );

  return (
    <div>
      <h1 className="mt-16 text-center text-3xl font-semibold">
        Standardized CV for {candidateName}
      </h1>
      {renderTable("Resume", resume)}
      {renderExperienceTable("Experience", experience)}
      {renderSubTable("Projects", projects)}
      {renderSubTable("Achievements Awards", achievementsAwards)}
      {renderSubTable("Academic Publications", academicPublications)}
      {renderSubTable("Other", other)}
      {renderJustificationTable("Justification View", justificationData)}
      <Footer />
    </div>
  );
}
