//uploader.js

import { storage } from "./firebase"

const upload = async (files, setDisabled, setCVs, setErrorMsg, setProgress) => {
    setDisabled(true); // Disable the upload button while uploading

    const uploadPromises = Array.from(files).map(file => {
        return new Promise((resolve, reject) => {
            if (file.type === 'application/pdf') {  // Allow only PDF files
                const sanitizedFileName = file.name.replace(/[\s\W]+/g, '_');
                setCVs((prevFiles) => [...prevFiles, sanitizedFileName]);
                const uploadTask = storage.ref(sanitizedFileName).put(file);

                uploadTask.on('state_changed', 
                    (snapshot) => {
                        // Progress function
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        setProgress(progress); // Update progress
                        console.log(`Upload is ${progress}% done`);
                    }, 
                    (error) => {
                        // Error function
                        console.error(error);
                        setErrorMsg('Upload failed');
                        reject(error);
                    }, 
                    () => {
                        // Complete function
                        console.log('Upload successful');
                        resolve();
                    }
                );
            } else {
                setErrorMsg('Unsupported Type');
                reject(new Error('Unsupported Type'));
            }
        });
    });

    try {
        await Promise.all(uploadPromises);
        setDisabled(false); // Enable the upload button after all uploads complete
        setProgress(0); // Reset progress
    } catch (error) {
        console.error('One or more uploads failed:', error);
    }
}

export default upload;